export const headerMapping = {
  'First Name': 'firstName',
  'Last Name': 'lastName',
  Email: 'email',
  'Member Email': 'customerEmail',
  Segments: 'segments',
  'Phone number': 'phone',
  Date: null,
  Address1: 'address.address1',
  Address2: 'address.address2',
};

export const getHeaderValue = (header, target) => {
  const mappedKey = headerMapping[header];
  if (!mappedKey) return null;
  const keys = mappedKey.split('.');
  let value = target;
  for (const key of keys) {
    value = value ? value[key] : null;
  }
  return value;
};

export const getMappedField = (header) => {
  return headerMapping[header] || header;
};
