import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { compose } from 'redux';
import { isEmpty } from 'lodash-es';
import { useQuery } from 'react-query';

import { postJourneyTodos, fetchSubscriptionJourney } from 'modules/api/common';
import useTodos from 'modules/v2/context/JourneyContextReducer';
import empty from 'assets/images/empty.png';
import { isLoading } from 'store/status';
import { CardWithVideo } from 'modules/v2/common/components';
import { VideoModal, Box } from 'modules/v2/common/AtomicDesign/atoms';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { withAuth } from 'modules/auth/containers';
import { withBooksStore, withReleaseNotes, withUserCredits } from 'modules/v2/containers';
import {
  ArrowRightTable,
  LeftArrowIcon,
  RightArrowIcon,
} from 'modules/v2/common/components/SvgIcon';
import { useGetBookPreviews } from 'modules/v2/pages/admin/BookPreviews/hooks';
import { notification } from 'modules/v2/common/utils';
import { getRouteDraftGenerateByBookIdFromWelcomeJourney } from 'modules/v2/draft/routes/navigation';
import { useTodoJourney } from 'modules/v2/context/JourneyContextProvider';
import { setBookLabel, setLabelColor } from 'modules/v2/pages/BookCatalog/utils';
import {
  editBookVideoSource,
  thumbnailSource,
  BOOK_NAVIGATION,
} from 'modules/v2/pages/WelcomeJourney/utils/constants';

import { BookLabel, BookPreview } from './components';

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1600 },
    items: 7,
    slidesToSlide: 7,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1366 },
    items: 6,
    slidesToSlide: 6,
  },
  minDesktop: {
    breakpoint: { max: 1366, min: 1200 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1200, min: 650 },
    items: 3,
    slidesToSlide: 3,
  },
  largeMobile: {
    breakpoint: { max: 650, min: 500 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 500, min: 1 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const EditBook = ({ books, history, visited }) => {
  const [showModal, setShowModal] = useState(false);
  const [booksList, setBooksList] = useState([]);
  const [finalBooksList, setFinalBooksList] = useState([]);
  const [bookPreviews, setBookPreviews] = useState([]);
  const [pdfPreview, setPdfPreview] = useState({});
  const isBooksLoading = isLoading(books.status);
  const [sendIntroVideoApi, setSendIntroVideoApi] = useState(true);
  const flipBookRef = useRef(null);

  const { data: subscriptionData } = useQuery(
    'fetchSubscriptionJourney',
    () => fetchSubscriptionJourney(),
    {
      onError: () => {
        notification.error({
          title: 'Failed to fetch',
          description: 'Fetching books failed',
        });
      },
    },
  );

  const { todos: fetchedTodos } = useTodoJourney();
  const { initializeTodos, tasks, todos } = useTodos();

  useGetBookPreviews({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    onSuccess: (response) => {
      setBookPreviews(response?.data?.data);
    },
    onError: (error) => {
      notification.error({
        title: 'Error',
        description: 'there was an error, please try again later',
      });
      throw new Error(error.message);
    },
  });

  useEffect(() => {
    initializeTodos(fetchedTodos);
  }, [fetchedTodos]);

  const combineBooksWithPreviews = (bookListData, bookPreviewsData) => {
    return bookListData.map((book) => {
      const matchingPreview = bookPreviewsData.find((preview) => preview.bookId === book._id);
      return matchingPreview ? { ...book, ...matchingPreview } : book;
    });
  };

  useEffect(() => {
    if (!isEmpty(books?.data) && books?.data?.length > 0) {
      const finalBookListData = books?.data.filter((book) => book.isAllowed);
      const subscriptionResponse = subscriptionData?.data?.subscription?.emphasis;
      let bookListData = finalBookListData;
      if (subscriptionResponse?.length) {
        bookListData = finalBookListData.filter((book) => subscriptionResponse.includes(book._id));
      }

      const combinedArray = combineBooksWithPreviews(bookListData, bookPreviews);
      setFinalBooksList(combinedArray);
      setBooksList(bookListData);
      const bookPdfPreview = combinedArray.filter(
        (combinedListData) => bookListData[0]?._id === combinedListData.bookId,
      );
      if (!isEmpty(bookPdfPreview)) {
        setPdfPreview(bookPdfPreview[0]);
      }
    }
  }, [bookPreviews, books, subscriptionData]);

  useEffect(() => {
    if (!isEmpty(tasks)) {
      const targetTodo = todos.find((todo) => todo.name === tasks?.setupBookJourney?.name);
      if (targetTodo) {
        const isIntroductionVideoIncomplete = targetTodo.steps.some(
          (step) => step?.name === 'viewIntroductionVideo' && !step?.isCompleted,
        );
        if (isIntroductionVideoIncomplete) {
          setShowModal(true);
        } else {
          setSendIntroVideoApi(false);
        }
      }
    }
  }, [tasks, fetchedTodos]);

  const handlePreviewUrlSetUp = (previewData) => {
    setPdfPreview(previewData);
  };

  let content;
  const VideoModalButtonClick = async () => {
    setShowModal(false);
    if (sendIntroVideoApi) {
      try {
        const response = await postJourneyTodos({
          taskId: tasks?.setupBookJourney?.taskId,
          stepId: tasks?.setupBookJourney?.stepId?.viewIntroductionVideo,
        });

        await postJourneyTodos({
          taskId: tasks?.setupBookJourney?.taskId,
          stepId: tasks?.setupBookJourney?.stepId?.editTheBook,
        });

        if (!(response.status === 201)) {
          notification.error({
            title: 'Saving failed',
            description: 'Unexpected response',
          });
        }
      } catch (error) {
        notification.error({
          title: 'Saving failed',
          description: 'An error occurred while posting journey todos',
        });
      }
    }
  };

  const onEditBookHandler = (bookid, flowPath) => {
    const routeToRedirect = getRouteDraftGenerateByBookIdFromWelcomeJourney(bookid, flowPath);
    history.push(routeToRedirect);
  };

  if (isBooksLoading || isEmpty(booksList)) {
    content = (
      <div
        role="status"
        className="mb-4 flex flex-wrap gap-y-4 gap-x-5 justify-center overflow-hidden animate-pulse"
      >
        {Array.from({ length: 40 }).map(() => (
          <div>
            <div className="flex items-center justify-center w-[216px] h-[328px] bg-gray-300 rounded dark:bg-gray-700" />
            <div className="h-4 mt-2 bg-gray-300 dark:bg-gray-700 w-full" />
            <div className="h-2.5 mt-1 bg-gray-300 dark:bg-gray-700 w-full" />
          </div>
        ))}
      </div>
    );
  }

  const hasBooksToShow = booksList?.length > 0;

  if (!hasBooksToShow) {
    content = (
      <div className="flex flex-col items-center justify-center h-[400px]">
        <img src={empty} alt="No books found" className="mb-3.5" />
        <p className="text-sm font-medium">Try a different keyword or </p>
      </div>
    );
  }

  return (
    <>
      {!visited && (
        <VideoModal
          title="Discover How to Edit Your Book"
          showModal={showModal}
          setShowModal={VideoModalButtonClick}
          videoSource={editBookVideoSource}
          icon={<ArrowRightTable width={20} height={20} />}
          buttonText="Start editing my book"
          onClick={VideoModalButtonClick}
        />
      )}
      <div className="flex flex-col items-center justify-center py-8 gap-8 bg-neutral-50">
        {/** TODO: update with dynamic value */}
        <CardWithVideo
          imageSource={thumbnailSource}
          title="How to Customize Your Book"
          content="Watch our video to learn how to edit your book. It shows quick edits to match your brand. You can choose your cover, add your photo, customize your bio, or include your broker logos."
          iconClick={() => setShowModal(!showModal)}
        />

        <Box className="w-full flex flex-col gap-4 p-6">
          <div className="w-full flex justify-between items-center">
            <h3 className="text-base font-medium text-neutral-800">Select your book</h3>
          </div>

          <Carousel
            swipeable
            draggable
            responsive={responsive}
            keyBoardControl
            renderArrowsWhenDisabled
            customTransition="all .5"
            transitionDuration={500}
            deviceType="desktop"
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px flex items-center justify-center"
            customLeftArrow={
              <LeftArrowIcon className="absolute top-1/2 left-[-5px] cursor-pointer" />
            }
            customRightArrow={
              <RightArrowIcon className="absolute top-1/2 right-[-5px] cursor-pointer" />
            }
          >
            {booksList?.length > 0 &&
              booksList.map((book) => {
                const currentBookPreview = finalBooksList.find(
                  (previews) => book._id === previews.bookId,
                );

                if (
                  book?.categoryDetails?.name === 'Dentist Guides' ||
                  (!book.isAllowed && !currentBookPreview)
                ) {
                  return null;
                }

                const bookStatus = book?.links?.status;

                return (
                  <div key={book._id} className="relative">
                    <BookPreview
                      book={book}
                      currentBookPreview={currentBookPreview}
                      onClickHandle={handlePreviewUrlSetUp}
                      linkUrl={getRouteDraftGenerateByBookIdFromWelcomeJourney(
                        book._id,
                        book?.milestone ? BOOK_NAVIGATION.VIEW : BOOK_NAVIGATION.CUSTOMIZE,
                      )}
                      key={book.name}
                      selectedBook={pdfPreview?.bookId}
                    />
                    {(book?.milestone || (currentBookPreview && !book?.isAllowed)) && (
                      <BookLabel
                        label={setBookLabel(bookStatus, book?.isAllowed, bookPreviews)}
                        className={setLabelColor(bookStatus)}
                      />
                    )}
                  </div>
                );
              })}
          </Carousel>

          <div>{content}</div>

          <div className="h-[480px]">
            {!isEmpty(pdfPreview) && (
              <afy-flip-book
                key={pdfPreview?.bookId}
                pdfurl={
                  pdfPreview?.milestone ? pdfPreview?.preview?.url : pdfPreview?.defaultPdfUrl
                }
                ref={flipBookRef}
              />
            )}
          </div>
          <div className="flex justify-end">
            {hasBooksToShow && (
              <Button
                type="primary"
                onClick={() =>
                  onEditBookHandler(
                    pdfPreview?.bookId,
                    pdfPreview?.milestone ? BOOK_NAVIGATION.VIEW : BOOK_NAVIGATION.CUSTOMIZE,
                  )
                }
              >
                {pdfPreview?.milestone ? 'View details' : 'Customize this book'}
              </Button>
            )}
          </div>
        </Box>
      </div>
    </>
  );
};

export default compose(withAuth, withUserCredits, withBooksStore, withReleaseNotes)(EditBook);
